/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');
        $('[data-nav]').toggleClass('visible');

        if ($('[data-nav]').hasClass('visible')) {
            $('[data-nav]').slideDown(300);
        } else {
            $('[data-nav]').slideUp(300);
        }
    });
});
